import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Pages/Header";
import "../Style/Header.css";
import "react-multi-carousel/lib/styles.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";

export default function Home() {
  const BannerPro1 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/BannerPro1.jpg";
  const BannerPro2 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/BannerPro2.png";
  const BannerPro3 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/BannerPro3.png";
  const BannerPro4 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/BannerPro4.png";
  const BannerPro5 =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/imgpro/BannerPro5.png";

  const Bgcontact = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg32.png";
  const Bgcontactt =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg34.png";
  const QRCodePc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg33.png";
  const BgLinePc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg5.png";
  const BgLineePc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg35.png";
  const IconLinePc =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg36.png";
  const BgPhonePc = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg13.png";

  const IconGamesAll =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg1.png";
  const IconCasino =
    "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg21.png";
  const IconSport = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg20.png";
  const IconSlot = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg19.png";
  const IconLotto = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg22.png";
  const BgGames = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg31.png";
  const Bgback = "https://fs.cdnxn.com/landingKing/Images/ImgPc/PcImg9.png";

  const HGamesPro1 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-1.png";
  const HGamesPro2 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-2.png";
  const HGamesPro3 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-3.png";
  const HGamesPro4 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-4.png";
  const HGamesPro5 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-5.png";
  const HGamesPro6 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-6.png";
  const HGamesPro7 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-7.png";
  const HGamesPro8 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-8.png";
  const HGamesPro9 = "https://fs.cdnxn.com/landingKing/Images/Games/KIng-9.png";
  const HGamesPro10 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-10.png";
  const HGamesPro11 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-11.png";
  const HGamesPro12 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-12.png";
  const HGamesPro13 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-13.png";
  const HGamesPro14 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-14.png";
  const HGamesPro15 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-15.png";
  const HGamesPro16 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-16.png";
  const HGamesPro17 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-17.png";
  const HGamesPro18 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-18.png";
  const HGamesPro19 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-19.png";
  const HGamesPro20 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-20.png";
  const HGamesPro21 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-21.png";
  const HGamesPro22 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-22.png";
  const HGamesPro23 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-23.png";
  const HGamesPro24 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-24.png";
  const HGamesPro25 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-25.png";
  const HGamesPro26 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-26.png";
  const HGamesPro27 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-27.png";
  const HGamesPro28 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-28.png";
  const HGamesPro29 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-29.png";
  const HGamesPro30 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-30.png";
  const HGamesPro31 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-31.png";
  const HGamesPro32 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-32.png";
  const HGamesPro33 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-33.png";
  const HGamesPro34 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-34.png";
  const HGamesPro35 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-35.png";
  const HGamesPro36 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-36.png";
  const HGamesPro37 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-37.png";
  const HGamesPro38 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-38.png";
  const HGamesPro39 =
    "https://fs.cdnxn.com/landingKing/Images/Games/KIng-39.png";

  const RegisterMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb7.png";
  const LoginMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb8.png";
  const BgGamesMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb23.png";
  const TextGamesAllMb =
    "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb24.png";
  const LineMb = "https://fs.cdnxn.com/landingKing/Images/ImgMb/ImgMb14.png";
  const [linkLogin, setLinkLogin] = useState("https://game.lsm99king.bet");
  const [linkRegister, setRegister] = useState(
    "https://game.lsm99king.bet/register"
  );
  return (
    <>
      <div>
        <Header />
        <div className="home">
          <div className="home--Pc">
            <div className="Banner--box">
              <div className="Banner--Pc">
                <Splide
                  className="slide"
                  options={{
                    rewind: false,
                    gap: "10px",
                    type: "loop",
                    autoplay: true,
                    arrows: false,
                  }}
                >
                  <SplideSlide>
                    <a href={linkLogin}>
                      <img src={BannerPro1} className="w-100" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href={linkLogin}>
                      <img src={BannerPro2} className="w-100" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href={linkLogin}>
                      <img src={BannerPro3} className="w-100" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href={linkLogin}>
                      <img src={BannerPro4} className="w-100" />
                    </a>
                  </SplideSlide>
                  <SplideSlide>
                    <a href={linkLogin}>
                      <img src={BannerPro5} className="w-100" />
                    </a>
                  </SplideSlide>
                </Splide>
              </div>
              <div className="contact--Pc">
                <img src={Bgcontactt} className="Bg--contact--Pc" />
                <div className="contact--box">
                  <a href="https://lin.ee/QvJ2AAA" target="_blank"><img src={QRCodePc} className="QRCode--Pc" /></a>
                  <a href="https://lin.ee/QvJ2AAA" target="_blank">
                    <img src={BgLineePc} className="BgLine--Pc"></img>{" "}
                  </a>
                </div>
              </div>
            </div>

            <div className="Games--all--box">
              <img src={IconGamesAll} className="Text--Games--All--Pc"></img>
              <div className="Games--all--Pc">
                <div>
                  <a href={linkLogin}>
                    <img src={IconCasino} className="Icon--Casino--Pc"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconSport} className="Icon--Sport--Pc"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconSlot} className="Icon--Slot--Pc"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconLotto} className="Icon--Lotto--Pc"></img>{" "}
                  </a>
                </div>
                <div className="Icon--Games--All--Pc">
                  <div className="Games--center--pc">
                    <div className="Games--bar--Pc">
                      <div>
                        <img src={HGamesPro1} className="icon--img--games" />
                        <img src={HGamesPro2} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro3} className="icon--img--games" />
                        <img src={HGamesPro4} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro5} className="icon--img--games" />
                        <img src={HGamesPro6} className="icon--img--games" />
                      </div>

                      <div>
                        <img src={HGamesPro7} className="icon--img--games" />
                        <img src={HGamesPro8} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro9} className="icon--img--games" />
                        <img src={HGamesPro10} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro11} className="icon--img--games" />
                        <img src={HGamesPro12} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro13} className="icon--img--games" />
                        <img src={HGamesPro14} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro15} className="icon--img--games" />
                        <img src={HGamesPro16} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro17} className="icon--img--games" />
                        <img src={HGamesPro18} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro19} className="icon--img--games" />
                        <img src={HGamesPro20} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro21} className="icon--img--games" />
                        <img src={HGamesPro22} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro23} className="icon--img--games" />
                        <img src={HGamesPro24} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro25} className="icon--img--games" />
                        <img src={HGamesPro26} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro27} className="icon--img--games" />
                        <img src={HGamesPro28} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro29} className="icon--img--games" />
                        <img src={HGamesPro30} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro32} className="icon--img--games" />
                        <img src={HGamesPro32} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro33} className="icon--img--games" />
                        <img src={HGamesPro34} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro35} className="icon--img--games" />
                        <img src={HGamesPro36} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro37} className="icon--img--games" />
                        <img src={HGamesPro38} className="icon--img--games" />
                      </div>
                      <div>
                        <img src={HGamesPro39} className="icon--img--games" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="home--Mb">
            <div className="Login--Regis--Mb">
              <a href={linkRegister}>
                {/* <img src={RegisterMb} className="Register--Mb"></img> */}
              </a>
              <a href={linkLogin}>
                <img src={LoginMb} className="Login--Mb"></img>
              </a>
            </div>
            <div className="Banner--Mb">
              <Splide
                className="slide"
                options={{
                  rewind: false,
                  gap: "10px",
                  type: "loop",
                  autoplay: true,
                  arrows: false,
                }}
              >
                <SplideSlide>
                  <a href={linkLogin}>
                    <img src={BannerPro1} className="w-100" />
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a href={linkLogin}>
                    <img src={BannerPro2} className="w-100" />
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a href={linkLogin}>
                    <img src={BannerPro3} className="w-100" />
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a href={linkLogin}>
                    <img src={BannerPro4} className="w-100" />
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a href={linkLogin}>
                    <img src={BannerPro5} className="w-100" />
                  </a>
                </SplideSlide>
              </Splide>
              <img src={IconGamesAll} className="Text--Games--All--Mb"></img>
              <div className="Games--all--Mb">
                <div>
                  <a href={linkLogin}>
                    <img src={IconCasino} className="Icon--Casino--Mb"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconSport} className="Icon--Sport--Mb"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconSlot} className="Icon--Slot--Mb"></img>{" "}
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={IconLotto} className="Icon--Lotto--Mb"></img>{" "}
                  </a>
                </div>
              </div>
              <img src={BgGamesMb} className="Bg--Games--Mb"></img>
              <div className="Games--bar--Mb">
                <div>
                  <a href={linkLogin}>
                    <img src={HGamesPro1} className="HGames--Pro--Mb1"></img>
                  </a>
                  <a href={linkLogin}>
                    <img src={HGamesPro2} className="HGames--Pro--Mb2"></img>
                  </a>
                </div>
                <div>
                  <a href={linkLogin}>
                    <img src={HGamesPro3} className="HGames--Pro--Mb3"></img>
                  </a>
                  <a href={linkLogin}>
                    <img src={HGamesPro4} className="HGames--Pro--Mb4"></img>
                  </a>
                </div>
                <img src={TextGamesAllMb} className="Text--GamesAll--Mb"></img>
              </div>
              <a href="https://line.me/R/ti/p/@768yajkg">
                <img src={LineMb} className="Line--Mb"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
